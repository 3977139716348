import styled from 'styled-components';

import Box from 'components/Box';

const ResourceStyleWrap = styled(Box)`
  font-size: 1.7rem;
  line-height: 1.8em;
  p {
    margin-top: 20px;
    .gatsby-resp-image-wrapper {
      margin-top: 40px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.colors.black};
  }
  h2 {
    font-size: 3rem;
    margin-top: 40px;
  }
  h3 {
    font-size: 2.4rem;
    margin-top: 30px;
  }
  h4 {
    font-size: 2rem;
    margin-top: 30px;
  }
  a {
    text-decoration: underline;
  }
  blockquote:not(.largeQuote) {
    margin-left: -20px;
    padding-left: 23px;
    border-left: 3px solid black;
    font-style: italic;
  }
  ol {
    padding-left: 25px;
    li {
      margin-top: 10px;
    }
    ol {
      list-style-type: lower-alpha;
      ol {
        list-style-type: lower-roman;
      }
    }
  }
  ul {
    padding-left: 25px;
    li {
      margin-top: 10px;
    }
  }
  hr {
    height: auto;
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    color: #000;
    text-align: center;
    &::before {
      content: '...';
      box-sizing: inherit;
      line-height: 1.4em;
      letter-spacing: 0.5em;
    }
  }
`;

export default ResourceStyleWrap;
