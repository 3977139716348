import { graphql, PageRendererProps } from 'gatsby';
import React from 'react';

import { CompanyDetails } from 'types/schema';
import { Resource } from 'types/schema';
import { formatPhoneNumber, getPhoneNumberHref } from 'utils/helpers';
import Box from 'components/Box';
import Flex from 'components/Flex';
import Grid from 'components/Grid';
import H1 from 'components/H1';
import InnerWrap from 'components/layout/InnerWrap';
import LatestResources from 'components/resources/LatestResources';
import Link from 'components/Link';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';
import PortableText from 'components/PortableText';
import ResourceStyleWrap from 'components/resources/ResourceStyleWrap';

export const query = graphql`
  fragment SanityImage on SanityImageWithAlt {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  query ResourcePostTemplateQuery($id: String!) {
    post: sanityResource(id: { eq: $id }) {
      id
      publishedAt(formatString: "MMMM D, YYYY")
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      textExcerpt
      _rawBody(resolveReferences: { maxDepth: 5 })
      author {
        author {
          name
        }
      }
    }
    sanityCompanyDetails(_id: { eq: "companyDetails" }) {
      phoneNumber
    }
  }
`;

type Props = PageRendererProps & {
  data: {
    post: Resource;
    sanityCompanyDetails: Pick<CompanyDetails, 'phoneNumber'>;
  };
  pageContext: {
    next: Resource;
    previous: Resource;
  };
};

const ResourcePostTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const { previous, next } = pageContext;

  const { title, publishedAt, slug, textExcerpt, _rawBody } = data.post;
  const { phoneNumber } = data.sanityCompanyDetails;
  const seoDescription = textExcerpt;

  return (
    <MainLayout
      seoProps={{
        title,
        description: seoDescription,
        // imagePublicURL: headerImage ? headerImage.childImageSharp.sharing.src : null,
        pagePath: `/resources/${slug.current}/`,
      }}
    >
      <OuterWrap pt={6} bg="white">
        <InnerWrap pb={0} maxWidth={700}>
          <Box variant="body1">
            <Link to="/resources/" color="darkGold">
              PLG Resources
            </Link>{' '}
            /{' '}
            <Link to={`/resources/${slug.current}/`} color="darkGold">
              {title}
            </Link>
          </Box>
          <H1
            variant="header1Serif"
            mt={6}
            color="black"
            sx={{ lineHeight: '1.1em', textTransform: 'none' }}
          >
            {title}
          </H1>
          <Flex mt={4}>
            <Box variant="body2" color="mediumGrey" flex="1">
              {publishedAt}
            </Box>
          </Flex>

          <ResourceStyleWrap mt={40}>
            <PortableText blocks={_rawBody} />
            <Box mt={7} color="grey700">
              <p>
                Should you have any questions regarding the above, or have a question related to a
                matter not contained within the subject of this article, please contact Carter Perks
                at <a href="mailto:c.perks@perkslawgroup.com">c.perks@perkslawgroup.com</a> or{' '}
                <a href={getPhoneNumberHref(phoneNumber)}>{formatPhoneNumber(phoneNumber)}</a>.
              </p>
              <p>
                Resources and articles within Perks Law Group are subject to the firm’s{' '}
                <Link to="/terms-of-use/">Terms of Use Policy</Link> and are meant to provide
                educational content and are not to be taken as legal advice.
              </p>
            </Box>
          </ResourceStyleWrap>

          <Grid
            mt={8}
            gridTemplateColumns={['1fr', '1fr', '1fr 1fr']}
            gridGap={20}
            variant="body2"
            sx={{ a: { flex: 1, '&:hover': { textDecoration: 'underline' } } }}
          >
            {previous && (
              <Link
                to={`/resources/${previous.slug.current}/`}
                rel="prev"
                display="flex"
                alignItems="baseline"
                gridColumn="1 / 2"
              >
                <Box mr={3} flexShrink={0}>
                  ←
                </Box>
                <div>{previous.title}</div>
              </Link>
            )}
            {next && (
              <Link
                to={`/resources/${next.slug.current}/`}
                rel="next"
                display="flex"
                alignItems="baseline"
                gridColumn="2 / 3"
                justifySelf="end"
              >
                <Box textAlign="right">{next.title}</Box>
                <Box ml={3} flexShrink={0}>
                  →
                </Box>
              </Link>
            )}
          </Grid>
        </InnerWrap>
      </OuterWrap>
      <LatestResources />
    </MainLayout>
  );
};

export default ResourcePostTemplate;
